import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../core/Header';
import ArtistSelect from '../../core/ArtistSelect';
import axios from 'axios';
import Globals from '../../core/globals';

function CampaignCreate() {
    const [form, setForm] = useState({
        name: '',
        releaseDate: '',
        artwork: null,
        usePreviousSaves: false,
        saveMail: false,
        releaseMail: false,
        isrc: '',
        artistId: '',
        features: [],
        urlSuffix: '',
        campaignType: 'SINGLE', // Default to SINGLE
    });

    const [preview, setPreview] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Generate the URL suffix based on artist and campaign title
    const generateUrlSuffix = (name, artistName) => {
        const campaignTitle = name.trim().replace(/\s+/g, '-').toLowerCase();
        const artist = artistName ? artistName.trim().replace(/\s+/g, '-').toLowerCase() : '';
        return artist ? `/${artist}/${campaignTitle}` : `/${campaignTitle}`;
    };

    // Update the URL suffix whenever the campaign title or artist changes
    useEffect(() => {
        const artistName = form.artistId?.label || '';
        const newUrlSuffix = generateUrlSuffix(form.name, artistName);
        setForm((prevForm) => ({
            ...prevForm,
            urlSuffix: newUrlSuffix,
        }));
    }, [form.name, form.artistId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setForm({ ...form, artwork: file });

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const handleArtistChange = (selectedArtist) => {
        setForm({ ...form, artistId: selectedArtist });
    };

    const handleFeatureArtistChange = (index, selectedArtist) => {
        const updatedFeatures = form.features.map((feature, i) =>
            i === index
                ? { ...feature, artistId: selectedArtist.value, artistName: selectedArtist.label }
                : feature
        );
        setForm({ ...form, features: updatedFeatures });
    };

    const addFeature = () => {
        setForm({
            ...form,
            features: [...form.features, { artistInRoster: false, artistId: '', artistName: '' }],
        });
    };

    const removeFeature = (index) => {
        const updatedFeatures = form.features.filter((_, i) => i !== index);
        setForm({ ...form, features: updatedFeatures });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setErrorMessage('');

        // Concatenate feature artist names and IDs
        const featureArtistsNames = form.features.map(feature => feature.artistName).join(', ');
        const featureArtistIds = form.features.map(feature => feature.artistId).join(',');

        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('releaseDate', form.releaseDate);
        if (form.artwork) {
            formData.append('artwork', form.artwork);
        }
        formData.append('usePreviousSaves', form.usePreviousSaves);
        formData.append('saveMail', form.saveMail);
        formData.append('releaseMail', form.releaseMail);
        formData.append('isrc', form.isrc);
        formData.append('artistId', form.artistId?.value || '');
        formData.append('featureArtistsNames', featureArtistsNames);
        formData.append('featureArtistIds', featureArtistIds);
        formData.append('urlSuffix', form.urlSuffix);
        formData.append('campaignType', form.campaignType); // Append campaign type

        try {
            await axios.post(`${Globals.backendUri}/campaigns`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            navigate('/campaigns');
        } catch (error) {
            setErrorMessage(error.response?.data || 'An error occurred. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Header isLoggedIn={true} logoutUser={() => console.log('Logged out')} />
            <div className="container mx-auto p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">Create a New Campaign</h2>
                <form className="space-y-8 bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>
                    {errorMessage && <div className="mb-4 text-red-600">{errorMessage}</div>}

                    {/* Campaign Title */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Campaign Title</label>
                        <input
                            type="text"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md"
                            placeholder="Enter campaign title"
                        />
                    </div>

                    {/* Release Type */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Release Type</label>
                        <select
                            name="campaignType"
                            value={form.campaignType}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md"
                        >
                            <option value="SINGLE">Single</option>
                            <option value="ALBUM">Album</option>
                        </select>
                        {form.campaignType === 'ALBUM' && (
                            <p className="mt-2 text-sm text-gray-600">No ISRC is needed for albums. The album will be added to the user’s library on release date.</p>
                        )}
                    </div>

                    {/* ISRC Code (only for SINGLE type) */}
                    {form.campaignType === 'SINGLE' && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">ISRC Code</label>
                            <input
                                type="text"
                                name="isrc"
                                value={form.isrc}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md"
                                placeholder="Enter ISRC code"
                            />
                        </div>
                    )}

                    {/* Release Date and Artwork */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Release Date</label>
                            <input
                                type="date"
                                name="releaseDate"
                                value={form.releaseDate}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Artwork Upload</label>
                            <div className="flex items-center justify-center w-full">
                                <label className="flex flex-col items-center w-full h-32 px-4 border-2 border-dashed rounded-lg cursor-pointer border-gray-300 text-gray-400 hover:border-blue-500">
                                    {preview ? (
                                        <img src={preview} alt="Artwork Preview" className="h-32 object-contain" />
                                    ) : (
                                        <div className="flex flex-col items-center justify-center">
                                            <svg className="w-12 h-12 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16V8m10 8V8m-5 4h4m-4 0H8"></path>
                                            </svg>
                                            <span className="text-sm">Click to upload artwork</span>
                                        </div>
                                    )}
                                    <input type="file" name="artwork" onChange={handleImageUpload} className="hidden" />
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Options */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-start">
                            <input
                                type="checkbox"
                                name="usePreviousSaves"
                                checked={form.usePreviousSaves}
                                onChange={handleChange}
                                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">Reuse previous pre-saves</label>
                        </div>
                        <div className="flex items-start">
                            <input
                                type="checkbox"
                                name="saveMail"
                                checked={form.saveMail}
                                onChange={handleChange}
                                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">Send mail after save</label>
                        </div>
                        <div className="flex items-start">
                            <input
                                type="checkbox"
                                name="releaseMail"
                                checked={form.releaseMail}
                                onChange={handleChange}
                                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label className="ml-3 block text-sm font-medium text-gray-700">Send mail on release day</label>
                        </div>
                    </div>

                    {/* Select Main Artist */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Select Main Artist</label>
                        <ArtistSelect
                            placeholder="Select main artist"
                            multiple={false}
                            onArtistChange={handleArtistChange}
                        />
                    </div>

                    {/* Feature Artists */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Feature Artists</label>
                        {form.features.map((feature, index) => (
                            <div key={index} className="mb-4 p-4 border border-gray-300 rounded-md shadow-sm">
                                <div className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        checked={feature.artistInRoster}
                                        onChange={(e) => {
                                            const updatedFeatures = form.features.map((feature, i) =>
                                                i === index ? {
                                                    ...feature,
                                                    artistInRoster: e.target.checked,
                                                    artistId: '',
                                                    artistName: ''
                                                } : feature
                                            );
                                            setForm({...form, features: updatedFeatures});
                                        }}
                                        className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    />
                                    <label className="ml-3 block text-sm font-medium text-gray-700">
                                        Artist is in roster
                                    </label>
                                    <button
                                        type="button"
                                        onClick={() => removeFeature(index)}
                                        className="ml-auto text-red-500 hover:text-red-700"
                                    >
                                        Remove
                                    </button>
                                </div>

                                {feature.artistInRoster ? (
                                    <ArtistSelect
                                        placeholder="Select feature artist"
                                        multiple={false}
                                        onArtistChange={(artist) => handleFeatureArtistChange(index, artist)}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        placeholder="Enter artist name"
                                        value={feature.artistName}
                                        onChange={(e) => {
                                            const updatedFeatures = form.features.map((feature, i) =>
                                                i === index ? {...feature, artistName: e.target.value} : feature
                                            );
                                            setForm({...form, features: updatedFeatures});
                                        }}
                                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addFeature}
                            className="flex items-center text-blue-500 hover:text-blue-700"
                        >
                            <svg
                                className="w-5 h-5 mr-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                            </svg>
                            Add Feature Artist
                        </button>
                    </div>

                    {/* URL Suffix */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">URL Suffix</label>
                        <input
                            type="text"
                            name="urlSuffix"
                            value={form.urlSuffix}
                            onChange={handleChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="/artistname/songname"

                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white px-6 py-2 rounded-md shadow-md ${isSaving ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'} focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Campaign'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CampaignCreate;
